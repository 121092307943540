import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import SEO, { createSEO } from 'components/Core/SEO';
import WebsiteLayout from 'layouts/websiteLayout';
import JobPostsHeroSection from 'components/Page/JobOpenings/JobPostsHeroSection';

const JobPostsPage = (props) => {
  const seo = createSEO(
    {
      ...props.data.contentfulPage?.seo,
    },
    props,
  );

  const breadcrumbs = {
    data: [
      {
        title: 'Careers',
        icon: null,
        url: '/careers',
      },
      {
        title: 'Job Openings',
        icon: null,
        url: null,
      },
    ],
    absolute: true,
  };

  return (
    <WebsiteLayout
      breadcrumbs={breadcrumbs}
      headerTheme="TRANS_BG_BLACK_COLOR"
      hero
      layout="industry"
    >
      <SEO {...seo} />
      <JobPostsContainer>
        <JobPostsHeroSection
          title="Job Openings"
          description="Find a role on one of our inspiring teams!"
        />
      </JobPostsContainer>
    </WebsiteLayout>
  );
};

export default JobPostsPage;

export const JobPostsPageQuery = graphql`
  query JobPostsPageQuery {
    contentfulPage(internalName: { eq: "Job Openings" }) {
      seo {
        ...Seo
      }
    }
    allWorkdayJob {
      edges {
        node {
          ...WorkdayJob
        }
      }
    }
  }
`;

const JobPostsContainer = styled.div`
  padding-top: 210px;

  @media (max-width: 1450px) {
    padding-top: 160px;
  }

  @media (max-width: 1199px) {
    padding-top: 100px;
  }
`;
